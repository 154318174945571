import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../pli/pli-card';
import '../../pli/pli-text';
import '../../pli/pli-table';
import '../../pli/pli-status-label';
import '../../pli/pli-user-bubble-list';
import { PliUserBubbleListProps } from '../../pli/pli-user-bubble-list';
import { defineHeaderItems } from '../../pli/pli-table';
import '../../pli/pli-enum-filter';
import { Task } from '@lit/task';
import { baseStyles, gridStyles } from 'pli';
import { urlParamsBuilder } from 'utils/url-params-builder';
import { consume } from '@lit/context';
import { StorageHandler } from 'storage-handler';
import { HttpClient } from 'aurelia-fetch-client';
import { clientContext } from 'context/client-context';
import { PliEnumFilterProps } from '../../pli/pli-enum-filter';
import { TMSResponse } from 'response-types/base-response';
import { nameFormatter } from 'utils/name-formatter';
import { useTotalPagesCount } from 'utils/use-total-pages-count';
import '../../pli/pli-pagination';
import { PliStatusLabelVariant } from 'pli/pli-status-label';
import { dateTimeFormatter } from 'utils/datetime-formatter';

type QueryPayload = {
    field: string | null;
    order: string | null;
    page: number;
    statuses: string[];
};

type CaseResponse = TMSResponse<Case>;

type Case = {
    caseId: number;
    name: string;
    created: Date;
    assigned: unknown[];
    customerId: string;
    status: string;
    customer: {
        name: string;
    };
};

@customElement('cases-overview')
class CasesOverview extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client: HttpClient | undefined;

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 1,
        },
        Name: {
            sortField: null,
            columnSpan: 1,
        },
        Customer: {
            sortField: null,
            columnSpan: 3,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: 'Created',
            columnSpan: 3,
        },
        Assigned: {
            sortField: null,
            columnSpan: 2,
        },
    });

    @state()
    _page = 1;

    @state()
    _field = null;

    @state()
    _order = null;

    @state()
    _total = 0;

    availableStatuses: PliEnumFilterProps['items'] = [
        { label: 'Investigating', value: 'Investigating' },
        { label: 'Resolved', value: 'Resolved' },
        { label: 'Reporting', value: 'Reporting' },
        { label: 'Reported', value: 'Reported' },
    ];

    @state()
    _statuses: string[] = this.availableStatuses.map(({ value }) => value) as string[];

    static styles = [baseStyles, gridStyles, css``];
    sortOrderKey: string = 'case-list-order';

    private _task = new Task(this, {
        task: async ([page, field, order, statuses]) => {
            const query = this.buildQuery({ page, field, order, statuses });
            const url = `cases?${query}`;
            const response: any = await this.client.get(url);
            const json = (await response.json()) as CaseResponse;
            this._total = json.total;

            return json;
        },
        args: () => [this._page, this._field, this._order, this._statuses] as const,
    });

    connectedCallback(): void {
        super.connectedCallback();

        const stored = StorageHandler.getFromStorage(this.sortOrderKey);
        if (stored !== null) {
            this._field = stored.field;
            this._order = stored.sortOrder;
        }
    }

    onChange = (event: CustomEvent) => {
        this._statuses = event.detail.value.map(({ value }) => value);
    };

    buildQuery(payload: QueryPayload): string {
        const { field, order, statuses, page } = payload;
        return urlParamsBuilder({ page: { no: page }, statuses, sort: { field, order } });
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    private _sort(event: CustomEvent) {
        this._field = event.detail.field;
        this._order = event.detail.order;
    }

    private _renderItem(item: Case): TemplateResult {
        return html`
            <tr>
                <td><strong>${item.caseId}</strong></td>
                <td>
                    <a data-link="navigate" href="customers/${item.customerId}/cases/${item.caseId}/#investigations"
                        >${item.name}</a
                    >
                </td>
                <td>
                    ${item.customer.name}
                </td>
                <td>
                    <pli-status-label variant="${item.status as PliStatusLabelVariant}"></pli-status-label>
                </td>
                <td>${dateTimeFormatter(item.created.toString())}</td>
                <td>
                    <pli-user-bubble-list
                        .items="${item.assigned as PliUserBubbleListProps['items']}"
                    ></pli-user-bubble-list>
                </td>
            </tr>
        `;
    }

    renderResult(data: CaseResponse) {
        const { isPaginationEnabled } = useTotalPagesCount(this._total);

        return html`
            <div class="grid-vertical gap-2">
                <pli-table
                    @sort="${this._sort}"
                    .headerItems="${this.headerItems}"
                    .items="${data.list}"
                    .renderTemplate="${this._renderItem}"
                    sortOrderKey="case-list-order"
                >
                </pli-table>
                ${isPaginationEnabled
                    ? html`<pli-pagination
                          @pagination-changed="${this._paginationHandler}"
                          page="${this._page}"
                          total="${this._total}"
                      ></pli-pagination>`
                    : null}
            </div>
        `;
    }

    render() {
        return html`
            <div class="grid-vertical gap-1">
                <pli-card>
                    <div class="grid">
                        <div class="col-span-2">
                            <pli-enum-filter
                                @change="${this.onChange}"
                                placeholder="Status"
                                .items="${this.availableStatuses}"
                            ></pli-enum-filter>
                        </div>
                    </div>
                </pli-card>
                <pli-card>
                    <div class="grid">
                        <div class="col-span-8">
                            <pli-text variant="h2" as="h2">Cases</pli-text>
                        </div>
                    </div>
                    ${this._task.render({
                        pending: () =>
                            html`<pli-table
                                .loading="${true}"
                                .headerItems="${this.headerItems}"
                                loadingRowsCount="${10}"
                            ></pli-table>`,
                        complete: (data) => this.renderResult(data),
                    })}
                </pli-card>
            </div>
        `;
    }
}
